<template>
    <div>
        <section class="my-0" :class="{'section': desktop}">
            <card class="border-0">
                <div class="row justify-content-md-center">
                    <div v-if="source.replies.length > 0 || type == 'realtor'" class="col-lg-8">
                        <div class="row row-grid">
                            <div class="col" :class="{'order-last': !desktop}">
                                <transition v-if="!desktop && source.replies.length > 0" name="fade" appear>
                                    <div v-if="type == 'realtor'" ref="chart" class="row mt-4 shadow rounded" @click="filterReply(replyFilter); collapseReply = false;" style="height: 8px; margin-left: 0.1rem; margin-right: 0.1rem;">
                                        <div ref="standby" :class="{'shadow-standby': replyFilter == 'standby', 'rounded-left': chart.leftCorner == 'standby', 'rounded-right': chart.rightCorner == 'standby'}" style="background-color: #7889e8; cursor:pointer;" @click="replyFilter == 'standby' ? replyFilter = 'header' : replyFilter = 'standby';" v-b-popover.hover.top="dataset.values[0]+' '+$tc(dataset.labels[0], dataset.values[0])"></div>
                                        <div ref="reject" :class="{'shadow-reject': replyFilter == 'reject', 'rounded-left': chart.leftCorner == 'reject', 'rounded-right': chart.rightCorner == 'reject'}" style="background-color: #f75676; cursor:pointer;" @click="replyFilter == 'reject' ? replyFilter = 'header' : replyFilter = 'reject'" v-b-popover.hover.top="dataset.values[1]+' '+$tc(dataset.labels[1], dataset.values[1])"></div>
                                        <div ref="accept" :class="{'shadow-accept': replyFilter == 'accept', 'rounded-left': chart.leftCorner == 'accept', 'rounded-right': chart.rightCorner == 'accept'}" style="background-color:#4fd69c; cursor:pointer;" @click="replyFilter == 'accept' ? replyFilter = 'header' : replyFilter = 'accept'" v-b-popover.hover.top="dataset.values[2]+' '+$tc(dataset.labels[2], dataset.values[2])"></div>
                                        <div ref="convert" :class="{'shadow-convert': replyFilter == 'convert', 'rounded-left': chart.leftCorner == 'convert', 'rounded-right': chart.rightCorner == 'convert'}" style="background-color: yellow;" v-b-popover.hover.top="dataset.values[3]+' '+$tc(dataset.labels[3], dataset.values[3])"></div>
                                    </div>
                                </transition>
                                <card shadow class="input-group-alternative" :class="{'mt-4': type == 'realtor'}">
                                    <div class="row">
                                        <div class="col">
                                            <div class="row align-items-center input-group-prepend">
                                                <h5 class="col" style="text-align: left !important">
                                                    {{$tc('dashboard.' + type, source.replies.length)}}
                                                </h5>
                                                <router-link :to="type == 'realtor' ? '/reply' : '/request'">
                                                    <base-button class="col-md-auto" type="success">+</base-button>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="source.replies.length > 0">
                                        <transition-group name="fade">
                                            <card type="secondary" class="mt-2 border-0" style="text-align: left; margin-bottom: 0.5px;" v-for="reply in replies" :key="reply._id">
                                                <router-link class="row" :to="'/response/' + reply._id">{{reply.location}}</router-link>
                                                <small class="row mt-2" style="float: left; color:darkgrey">{{timestamp(reply.date.creation)}}</small>
                                            </card>
                                        </transition-group>
                                        <i v-if="source.replies.length > 3" :class="[{'mt-4': true, 'ni ni-bold-down': !collapseReply}, {'ni ni-bold-up': collapseReply}]" @click="filterReply((collapseReply ? 'header' : 'all')); collapseReply = !collapseReply; replyFilter = 'all'"></i>
                                    </div>
                                </card>
                                <transition v-if="desktop && source.replies.length > 0" name="fade" appear>
                                    <div v-if="type == 'realtor'" ref="chart" class="row mt-4 shadow rounded" @click="filterReply(replyFilter); collapseReply = false;" style="height: 8px; margin-left: 0.1rem; margin-right: 0.1rem;">
                                        <div ref="standby" :class="{'shadow-standby': replyFilter == 'standby', 'rounded-left': chart.leftCorner == 'standby', 'rounded-right': chart.rightCorner == 'standby'}" style="background-color: #7889e8; cursor:pointer;" @click="replyFilter == 'standby' ? replyFilter = 'header' : replyFilter = 'standby';" v-b-popover.hover.top="dataset.values[0]+' '+$tc(dataset.labels[0], dataset.values[0])"></div>
                                        <div ref="reject" :class="{'shadow-reject': replyFilter == 'reject', 'rounded-left': chart.leftCorner == 'reject', 'rounded-right': chart.rightCorner == 'reject'}" style="background-color: #f75676; cursor:pointer;" @click="replyFilter == 'reject' ? replyFilter = 'header' : replyFilter = 'reject'" v-b-popover.hover.top="dataset.values[1]+' '+$tc(dataset.labels[1], dataset.values[1])"></div>
                                        <div ref="accept" :class="{'shadow-accept': replyFilter == 'accept', 'rounded-left': chart.leftCorner == 'accept', 'rounded-right': chart.rightCorner == 'accept'}" style="background-color:#4fd69c; cursor:pointer;" @click="replyFilter == 'accept' ? replyFilter = 'header' : replyFilter = 'accept'" v-b-popover.hover.top="dataset.values[2]+' '+$tc(dataset.labels[2], dataset.values[2])"></div>
                                        <div ref="convert" :class="{'shadow-convert': replyFilter == 'convert', 'rounded-left': chart.leftCorner == 'convert', 'rounded-right': chart.rightCorner == 'convert'}" style="background-color: yellow;" v-b-popover.hover.top="dataset.values[3]+' '+$tc(dataset.labels[3], dataset.values[3])"></div>
                                    </div>
                                </transition>
                            </div>
                            <div class="col" v-if="type == 'realtor'">
                                <div :class="{'mt-4': desktop}">
                                    <card shadow :type="credit > 0 ? 'primary' : 'warning'" class="input-group-alternative" id="credit">
                                        <h4 v-if="credit > 0" style="color: white;">
                                            <i18n path="balance.have">
                                                <template v-slot:balance>
                                                    <span style="font-weight: bold;">{{credit}} </span>{{$tc('balance.credit', credit)}}
                                                    <span v-if="credit <= 10 && (source.replies.length + source.properties.length) < 10">gratuitos</span>
                                                </template>
                                            </i18n>
                                        </h4>
                                        <h4 v-else style="color: white;">{{$t('balance.lack')}}</h4>
                                        <router-link to="/credit">
                                            <base-button outline block @mouseover.native="text_color = credit > 0 ? 'primary' : 'warning'" @mouseleave.native="text_color = 'white'" :textColor="text_color" type="secondary" size="sm">
                                                {{$t('balance.buy')}}
                                            </base-button>
                                        </router-link>
                                    </card>
                                </div>
                                <card shadow class="input-group-alternative" :class="{'mt-4': type == 'realtor'}">
                                    <div class="row">
                                        <div class="col">
                                            <div class="row align-items-center input-group-prepend">
                                                <h5 class="col" style="text-align: left !important">
                                                    {{$t('property.dashboard.title')}}
                                                </h5>
                                                <router-link to="/ad">
                                                    <base-button class="col-md-auto" type="success">+</base-button>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="source.properties.length > 0">
                                        <transition-group name="fade">
                                            <card type="secondary" class="mt-2 border-0" style="text-align: left; margin-bottom: 0.5px;" v-for="property in properties" :key="property._id">
                                                <router-link class="row" :to="'/property/' + property._id">{{property.location}}</router-link>
                                                <small class="row mt-2" style="float: left; color:darkgrey">{{timestamp(property.date.creation)}}</small>
                                            </card>
                                        </transition-group>
                                        <i v-if="source.properties.length > 3" :class="[{'mt-4': true, 'ni ni-bold-down': !collapseProperty}, {'ni ni-bold-up': collapseProperty}]" @click="filterProperty((collapseProperty ? 'header' : 'all')); collapseProperty = !collapseProperty; propertyFilter = 'all'"></i>
                                    </div>
                                </card>
                            </div>
                        </div>
                    </div>
                    <div v-else class="container d-flex">
                        <div class="col px-0">
                            <div class="row row-grid align-items-center">
                                <div class="col-md-6">
                                    <img v-lazy="'../img/svg/reply.svg'" />
                                </div>
                                <div class="col-md-6 pl-md-5" style="text-align: left;">
                                    <i18n path="notification.dashboard.p1" tag="p" class="lead text-italic">
                                        <template v-slot:type>{{$t('notification.dashboard.' + type)}}</template>
                                        <template v-slot:here>
                                            <router-link style="font-weight: bold;" @click="$gtag.event('dash_notification')" :to="type == 'realtor'? '/reply' : '/request'">{{$t('notification.dashboard.here')}}</router-link>
                                        </template>
                                    </i18n>
                                    <i18n path="notification.dashboard.p2" tag="p" class="lead text-italic">
                                        <template v-slot:profile>
                                            <router-link style="font-weight:bold;" @click="$gtag.event('dash_profile')" :to="'/profile/' + user_id">{{$t('notification.dashboard.profile')}}</router-link>.
                                        </template>
                                    </i18n>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </card>
        </section>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     loader="dots"
                     :is-full-page="true"
                     :opacity="1"
                     color="#5271ff"></loading>
        </div>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import $ from 'jquery';

    export default {
        name: 'Dashboard',

        components: {
            Loading,
        },

        data() {
            return {
                credit: 0,
                text_color: 'white',
                model: {},
                source: {
                    replies: [],
                    properties: [],
                },
                replies: [],
                properties: [],
                collapseReply: false,
                collapseProperty: false,
                isLoading: true,
                type: this.$store.state.type,
                user_id: this.$store.state._id,
                dataset: {
                    refs: ['standby', 'reject', 'accept', 'convert'],
                    labels: ['chart.label.standby', 'chart.label.reject', 'chart.label.accept', 'chart.label.convert'],
                    values: []
                },
                replyFilter: 'all',
                propertyFilter: 'all',
                chart: {
                    leftCorner: '',
                    rightCorner: '',
                },
                desktop: screen.width > 570,
            }
        },

        methods: {
            load(field, data) {
                this.$data[field] = data;
            },

            timestamp(date) {
                return new Date(date * 1000).toLocaleDateString()
            },

            updateObjects(objects) {
                var load = this.load;
                var data = this.$data;
                var gtag = this.$gtag;
                var plot = this.plot;
                var filterReply = this.filterReply;

                data.source.replies = objects;
                load('isLoading', false);

                if (objects.length > 0) {
                    filterReply('header');
                    plot();
                } else {
                    gtag.event('actionless_login');
                }
            },

            updateProperties(objects) {
                var data = this.$data;
                data.source.properties = objects;

                if (objects.length > 0) {
                    this.filterProperty('header');
                }
            },

            getProperties() {
                //var isRequestor = this.$store.state.type == 'requestor';
                var updateProperties = this.updateProperties;
                $.ajax({
                    url: 'https://api.immobl.com/property',
                    type: 'GET',
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true,
                    success: function (response) {
                        updateProperties(response.properties);
                        //if (isRequestor) {
                        //    load('isLoading', false);
                        //}
                    },
                    //error: function (err) {
                    //    //console.log(err);
                    //}
                });
            },

            getObjects(objectType) {
                //var isRequestor = this.$store.state.type == 'requestor';
                var updateObjects = this.updateObjects;
                $.ajax({
                    url: 'https://api.immobl.com/' + objectType,
                    type: 'GET',
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true,
                    success: function (response) {
                        updateObjects(response.objects);
                        //if (isRequestor) {
                        //    load('isLoading', false);
                        //}
                    },
                    //error: function (err) {
                    //    //console.log(err);
                    //}
                });                
            },
            plot() {
                var data = null;
                var refs = this.$refs;
                var dataset = this.dataset;
                var chart = this.chart;
                $.ajax({
                    url: 'https://api.immobl.com/retrieve/performance/realtor?type=session&select_key=realtor_id&session_key=_id',
                    type: 'GET',
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true,
                    success: function (response) {
                        if (response.success) {
                            data = response.data;

                            dataset.refs.forEach((ref) => { dataset.values.push(data[ref]) });

                            var sum = dataset.values.reduce(function (acc, val) { return acc + val; }, 0);
                            if (sum > 0) {
                                var percentage;
                                var displayed = [];
                                var ref
                                for (let i = 0; i < dataset.values.length; i++) {
                                    ref = dataset.refs[i]
                                    percentage = dataset.values[i] * 100 / sum;
                                    refs[ref].style.width = percentage + "%";

                                    if (percentage > 0) {
                                        displayed.push(ref)
                                    }
                                }

                                if (displayed.length > 0) {
                                    chart.leftCorner = displayed[0];
                                    chart.rightCorner = displayed.reverse()[0];
                                }
                            }
                        }
                    },
                    //error: function (err) {
                    //    console.log(err);
                    //}
                });
            },

            filterReply(filter) {
                var properties = this.source.replies.slice();
                if (filter == 'header') {
                    this.replies = properties.slice(-3);
                } else if (filter == 'all') {
                    this.replies = properties;
                } else {
                    this.replies = [];
                    for (let i = 0; i < properties.length; i++) {
                        if (properties[i].reaction == filter) {
                            this.replies.push(properties[i]);
                        }
                    }
                }
                this.replies.reverse()
            },

            filterProperty(filter) {
                var propertiesSource = this.source.properties.slice();
                if (filter == 'header') {
                    this.properties = propertiesSource.slice(-3);
                } else if (filter == 'all') {
                    this.properties = propertiesSource;
                }

                this.properties.reverse()
            },
        },

        mounted() {
            this.$gtag.set({ 'user_id': this.$store.state._id });
            var load = this.load;
            var getObjects = this.getObjects;

            if (this.$store.state.type == 'requestor') {
                getObjects('request');
            } else {
                $.ajax({
                    url: 'https://api.immobl.com/solvency',
                    type: 'GET',
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true,
                    success: function (response) {
                        if (response.success) {
                            load('credit', response.data.credit);
                            //load('isLoading', false);
                        }
                    },
                    //error: function (err) {
                    //    //console.log(err);
                    //}
                });
                getObjects('reply');
                this.getProperties();
            }

            var query = this.$route.query;

            if (Object.keys(query).length > 0 || (query.collection_status == 'approved' || query.collection_status == 'pending')) {
                var gtag = this.$gtag;
                $.ajax({
                    url: 'https://api.immobl.com/webhook?type=analytics',
                    contentType: 'application/json;charset=UTF-8',
                    type: 'POST',
                    data: JSON.stringify({ data: { id: query.collection_id } }),
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true,
                    success: function (response) {
                        gtag.event('purchase', {
                            "transaction_id": query.colletion_id,
                            "value": response.payment.value,
                            "currency": response.payment.currency,
                            "items": [
                                {
                                    "id": query.preference_id,
                                    "name": response.payment.name,
                                    "quantity": 1,
                                    "price": response.payment.price,
                                }
                            ]
                        });
                    },
                    //error: function (err) {
                    //    //console.log(err);
                    //}
                })
            }
        }

    }
</script>

<style scoped>
    .shadow-standby {
        -webkit-box-shadow: -1px 0px 14px -2px rgba(92,135,242,1);
        -moz-box-shadow: -1px 0px 14px -2px rgba(92,135,242,1);
        box-shadow: -1px 0px 14px -2px rgba(92,135,242,1);
    }

    .shadow-reject {
        -webkit-box-shadow: -1px 0px 14px -2px rgba(168,20,20,1);
        -moz-box-shadow: -1px 0px 14px -2px rgba(168,20,20,1);
        box-shadow: -1px 0px 14px -2px rgba(168,20,20,1);
    }

    .shadow-accept {
        -webkit-box-shadow: -1px 0px 14px -2px rgba(20,166,113,1);
        -moz-box-shadow: -1px 0px 14px -2px rgba(20,166,113,1);
        box-shadow: -1px 0px 14px -2px rgba(20,166,113,1);
    }

    .shadow-convert {
        -webkit-box-shadow: -1px 0px 14px -2px rgba(255,255,51,1);
        -moz-box-shadow: -1px 0px 14px -2px rgba(255,255,51,1);
        box-shadow: -1px 0px 14px -2px rgba(255,255,51,1);
    }

    .form {
        width: 50%;
        margin-left: 6px;
    }

    .flip-list-move {
        transition: transform 10s;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.4s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>